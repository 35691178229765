<template>
  <div id="base">
    <div id="leftNav" v-if="num === 90">
      <md-button class="nb" v-on:click="num = 1;getLoginCookie()">Change Password</md-button>
      <button class="nb" v-on:click="num = 2">Take Health Survey</button>
      <button class="nb" v-on:click="num = 3">Upload To Picture Diary</button>
      <button class="nb" v-on:click="num = 4">Make Notes</button>
      <button class="nb" v-on:click="getMessages();num = 5;">View Notifications</button>
      <button class="nb" v-on:click="num = 6">User Agreement</button>
      <button class="nb" v-on:click="num = 7">History/Schedule</button>
      <button class="nb" v-on:click="num = 8">Booking Progress</button>
    </div>
  <div id = "homePage" v-if="num === 0">
    
     <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">Welcome </h3>
        <md-badge class="md-primary md-caption" md-content="2" md-dense >
        <md-button class="md-icon-button" v-on:click="getMessages();num = 5;">
          <md-icon>notifications</md-icon>
        </md-button>
      </md-badge>
    <md-speed-dial md-event="click" style="display:contents;margin-left:30px" md-direction="bottom">
      <md-speed-dial-target class="md-primary">
        <md-icon>face_3</md-icon>
      </md-speed-dial-target>
      <md-speed-dial-content>
        <md-button class="md-icon-button">
          <md-icon>settings</md-icon>
        </md-button>
        <md-button class="md-icon-button" onclick="location.href='https://fenixvida.co.za'">
          <md-icon>logout</md-icon>
        </md-button>
      </md-speed-dial-content>
    </md-speed-dial>
      </div>
    </md-toolbar>

<div style="display:inline-flex;width:100%">


    <div id="cardsDivDiv" style="width:70%">

  <div id="cardsDiv" style="display:flex">
    <md-card class="md-primary" md-with-hover style="width:48%;margin-left:25px;margin-right:10px;margin-top:10px;margin-bottom:10px">
      <md-ripple>
        <md-card-header>
          <div class="md-title" style="color:#F26A24">Treatment Progress</div>
        </md-card-header>
        <md-card-content>
         <div class="circle-wrap" >
        <div class="circle">
          <div class="mask full" :style="{ 'transform': 'rotate('+ this.calcRotation.transform + ')'}">
            <div class="fill" :style="{ 'transform': 'rotate('+ this.calcRotation.transform + ')'}"></div>
          </div>
          <div class="mask half">
            <div class="fill" :style="{ 'transform': 'rotate('+ this.calcRotation.transform + ')'}"></div>
          </div>
          <div class="inside-circle" style="color:#5C5C5C">{{this.progressPercentage}}%</div>
        </div>
      </div>
        </md-card-content>
        <md-card-actions>
            <md-button style="color:#1AB14B">Show More</md-button>
          </md-card-actions>
      </md-ripple>
    </md-card>
    <md-card class="md-primary" md-with-hover style="width:48%;margin-left:5px;margin-right:25px;margin-top:10px;margin-bottom:10px">
      <md-ripple>
        <md-card-header>
          <div class="md-title" style="color:#F26A24">Appointment Schedule</div>
        </md-card-header>
        <md-card-content style="display:flex;justify-content:center">
             <div style="display:block">
             <md-icon class="md-size-5x" style="height:150px;margin-left:50px;margin-top:5px;margin-bottom:5px;color:#5C5C5C">events</md-icon>
             </div>
             <div id="DateWord" style="color:#5C5C5C">5 May</div>
        </md-card-content>
        <md-card-actions>
            <md-button style="color:#1AB14B">Show More</md-button>
          </md-card-actions>
      </md-ripple>
    </md-card>
  </div>
  <div id="cardsDiv" style="display:flex">
     <md-card class="md-primary" md-with-hover style="width:48%;margin-left:30px;margin-right:25px;margin-top:10px;margin-bottom:10px">
      <md-ripple>
        <md-card-header>
          <div class="md-title" style="color:#F26A24">Treatment Album</div>
        </md-card-header>
        <md-card-content style="display:flex;justify-content:center">
             <div style="display:block">
             <md-icon class="md-size-5x" style="height:150px;margin-left:-60px;margin-top:5px;margin-bottom:5px;color:#5C5C5C">collections</md-icon>
             </div>
        </md-card-content>
        <md-card-actions>
            <md-button style="color:#1AB14B" v-on:click="getImages();num = 3">Show More</md-button>
          </md-card-actions>
      </md-ripple>
    </md-card>

    <md-card class="md-primary" md-with-hover style="width:48%;margin-left:5px;margin-right:25px;margin-top:10px;margin-bottom:10px">
      <md-ripple>
        <md-card-header>
          <div class="md-title" style="color:#F26A24">Treatment Notes</div>
        </md-card-header>
        <md-card-content>
           <md-icon class="md-size-5x" style="height:150px;margin-left:-60px;margin-top:5px;margin-bottom:5px;color:#5C5C5C" >edit_note</md-icon>
        </md-card-content>
        <md-card-actions>
            <md-button style="color:#1AB14B" v-on:click="num = 4">Show More</md-button>
          </md-card-actions>
      </md-ripple>
    </md-card>
  </div>
  </div>
  <div id="todo" style="display:inline-flex;margin-top:10px">
      <md-card>
      <md-card-header>
        <div class="md-title" style="color:#F26A24">To Do List</div>
      </md-card-header>

      <md-card-content style="display:flex;flex-direction:column">
        <md-button v-on:click="num=1" style="color:#1AB14B">Change default password</md-button>
        <md-button v-on:click="num=2" style="color:#1AB14B">Take Health Survey</md-button>
      </md-card-content>
    </md-card>
    </div>
</div>
  </div>
    <div id="SurveyDiv" v-if="num === 2">
       <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">Health Survey</h3>
        <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" v-on:click="num=0">
          <md-icon >arrow_back</md-icon>
  </md-button>
      </div>
      </md-toolbar>
      <Survey :survey="survey" />
    </div>
    <div id="changePassword" v-if="num === 1">
                 <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">Change Password</h3>
        <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" v-on:click="num=0">
          <md-icon >arrow_back</md-icon>
  </md-button>
      </div>
      </md-toolbar>
      <input type="text" v-model="ppp" placeholder="new password" style="margin-top:40px"/>
      <button class="md-raised" v-on:click="changeDefaultPass(ppp)" style="margin-top:40px">Change Password</button>
    </div>
    <div id="View Messages" v-if="num === 55">
      <label>My Messages</label>
      <div id="inbox" >
        <div id="inboxHeader">
          <button id="inboxCtl">read messages</button><button id="inboxCtl">unread messages</button>
        </div>
        <div id="inboxMessages">
          <div id="message" v-for="(f, index) in this.messageArray" :key="index"><button id="messageButton" v-on:click="readMessage(index)">{{f.title}}</button></div>
          <label id="contentHeader">Contents</label>
          <p id="mc" style="white-space: pre-line;">{{this.messageContents}}</p>
        </div>
      </div>
      
    </div>
    <div id="UploadPictures" v-if="num === 3">
           <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">My Album</h3>
        
          <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" @click="sd = true">
            <md-icon >add_a_photo</md-icon>
          </md-button>

        <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" v-on:click="num=0">
          <md-icon >arrow_back</md-icon>
  </md-button>
      </div>
      </md-toolbar>

    <div>
      
    <md-dialog :md-active.sync="sd">
      <md-dialog-title>Upload an Image</md-dialog-title>


      <md-dialog-actions>
      <input type="file" id="file" ref="file" v-on:change="uploadImage($event)"/>
      <button v-on:click="submitForm();sd=false">Submit Image</button>
      </md-dialog-actions>
    </md-dialog>

<div class="md-elevation-5" id="squaregrid" >
          <div id="item" class="item" v-for="(f, index) in this.imageArray" :key="index"><img class="userImage md-elevation-5" :src="f" /></div>
      </div>

    </div>
    </div>

        <div id="UserNotes" v-if="num === 4">
           <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">My Notes</h3>
        <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" v-on:click="num=0">
          <md-icon >arrow_back</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <div>
    </div>
 
    <div  id="inbox">

    <md-list class="md-triple-line md-dense md-elevation-2">
      <md-list-item>
        <md-subheader style="align-items:flex-end;font-weight:600">My Notes</md-subheader>
      </md-list-item>

      <md-divider class="md-inset" style="background-color:black"></md-divider>

      <div id="MiniMessageDiv">

      <md-list-item @click="alert(this.firstname)">
          <md-icon>description</md-icon>
        <span class="md-list-item-text">Message Title</span>
      </md-list-item>
      <md-divider class="md-inset" style="background-color:grey;margin-left:70px"></md-divider>

      </div>
    </md-list>
    
    <div class="md-elevation-5" id="mDisplay">
      <md-field>
      <input v-model="message" placeholder="Title" style="width:100%;white;border-bottom-color:#F26A24;border-right-color:#F26A24;border-top:hidden;border-left:hidden" />
    </md-field>
    <md-field>
      <textarea v-model="message" placeholder="Contents" style="width:100%;height:500px;border-top-color:#F26A24;border-left-color:#F26A24;border-right-color: white;border-bottom-color:white;"></textarea>
    </md-field> 
      <md-button class="md-raised" style="background-color:RGB(26,177,75,0.5);flex-direction:flex-end">Submit</md-button>        
    </div>
    
    </div>
    </div>

    <div id="Notifications" v-if="num === 5">
    <md-toolbar class="md-medium" style="width:97%;margin-right:30px;margin-left:25px">
      <div class="md-toolbar-row">
        <h3 class="md-title" style="flex: 1">Notifications</h3>
        <md-button class="md-icon-button" style="background-color:RGB(26,177,75,0.5)" v-on:click="num=0">
          <md-icon >arrow_back</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <div id="inbox">

    <md-list class="md-triple-line md-dense md-elevation-2" style="height:600px;overflow-y:scroll">
      <md-list-item>
        <md-subheader style="align-items:flex-end;font-weight:600">Notifications</md-subheader>
      </md-list-item>

      <md-divider class="md-inset" style="background-color:black"></md-divider>

      <div id="MiniMessageDiv" v-for="(f, index) in this.messageArray" :key="index">

      <md-list-item v-on:click="readMessage(index)">
          <md-icon>email</md-icon>
        <span class="md-list-item-text">{{f.title}}</span>
      </md-list-item>
      <md-divider class="md-inset" style="background-color:grey;margin-left:70px"></md-divider>

      </div>
    </md-list>
    
    <div class="md-elevation-5" id="mDisplay">
      <h1>{{this.messageTitle}}</h1>
    <md-field>
      <md-textarea v-model="this.messageContents" readonly></md-textarea>
    </md-field>         
    </div>
    
    </div>
    
    </div>

    <div id="SurveyView" v-if="num === 100">
      <select v-model="surveyList" v-on:change="surveySelect($event)">
        <option
          v-for="survey in availableSurveys"
          v-bind:key="survey"
          v-bind:survey="availableSurveys.survey"
        >
          {{ survey.survey }}
        </option>
      </select>

      <button id="surveyButton" v-on:click="printSurvey()">Display Survey</button>
      <div id="sv">
        <table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dataPoint, i) in dataForTable" :key="i">
              <td>{{i}}</td>
              <td>{{dataPoint}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    
  </div>
  </div>
</template>

<script>
import "survey-vue/survey.min.css";
import "survey-vue/survey.min.css";
import { Survey, StylesManager, Model } from "survey-vue";
import axios from "axios";
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.css';
import Vue from 'vue';

Vue.use(VueMaterial);
//import {logie} from '../components/xuvar.js'
StylesManager.applyTheme("stone");
var surveyJson = {
  title: "health questions",
  description: "Extended Health Questionnaire",
  pages: [
    {
      name: "page1",
      elements: [
        { type: "text", name: "First name" },
        { type: "text", name: "Surname" },
        { type: "text", name: "Email" },
        { type: "text", name: "What is the reason for consultation?" },
        {
          type: "text",
          name: "Please tell me the 5 main physical complaints you have in order of their importance.",
        },
        {
          type: "text",
          name: "Please tell me your health history. (E.g. surgeries, diagnoses, diseases, conditions)",
        },
        {
          type: "text",
          name: "Please tell me the health history of your immediate family",
        },
        { type: "text", name: "Please list any allergies you may have." },
        {
          type: "radiogroup",
          name: "Are you pregnant?",
          choices: ["Yes", "No"],
        },
        {
          type: "rating",
          name: "On a scale of 1 to 10, how do you feel? (1 = terrible, 10 = great!)",
          rateMax: 10,
        },
        {
          type: "dropdown",
          name: "Do you exercise regularly?",
          choices: [
            "No",
            "Less than once per week",
            "1 to 2 times per week",
            "3 to 6 times per week",
            "Daily(no rest days)",
          ],
        },
        {
          type: "text",
          name: "Please state what prescriptions and over the counter medications you take and the reason for each. (If you take none, then please put none.) *",
        },
        {
          type: "radiogroup",
          name: "Acidic foods upset my stomach",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get chilled often. I am cold when others are not.",
          title: "I get chilled often. I am cold when others are not.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I feel like I have a lump in my throat, or clear my throat often.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Dry mouth, eyes, nose",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My pulse speeds up after eating",
          title: "My pulse speeds up after eating",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question1",
          title:
            "I feel like I have a lump in my throat, or clear my throat often.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am keyed up and have trouble calming down",
          title: "I am keyed up and have trouble calming down",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Cuts heal slowly",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I gag easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am unable to relax, or startle easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My extremities are cold or clammy",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Strong light irritates my eyes",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My urine amount is reduced",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My heart pounds after I lie down",
          title: "My heart pounds after I lie down",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a nervous stomach",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My appetite is reduced",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get cold sweats often",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Fever easily raised",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get nerve pain",
          title: "I get nerve pain",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I seem to stare and seldom blink",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get a sour stomach frequently",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Joint stiffness occurs after rising in the morning",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get muscle, leg, toe cramps at night",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get butterflies in my stomach or stomach cramps",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My eyes or nose are watery",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My eyes blink often",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My eyelids get swollen and puffy",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get indigestion soon after meals",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I always seem hungry or I feel lightheaded often",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My digestion seems rapid",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I vomit frequently",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I experience hoarseness frequently",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My breathing seems irregular",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My pulse is slow and may seem irregular",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My gag reflex is slow to react",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have difficulty swallowing",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I alternate between constipation and diarrhea",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am a slow starter in the morning",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I rarely get chilled",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I perspire easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My circulation is poor; I am sensitive to cold",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am subject to colds or I have asthma or bronchitis",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I tend to eat when I'm nervous",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have an excessive appetite",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get hungry between meals",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get irritable before meals or if meals are delayed",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get shaky when I'm hungry",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get fatigued easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get lightheaded if meals are delayed",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My heart palpitates if meals are missed or delayed",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get afternoon headaches",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Overeating sweets upsets my stomach",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I tend to wake up after only a few hours sleep and then it's hard to get back to sleep",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I crave candy or coffee in the afternoons",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get moods of depression or melancholy",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have abnormal cravings for sweets or snacks",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My hands and feet go to sleep easily, numbness",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I sigh frequently",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I seem to breathe heavily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I react to high altitudes",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I tend to want to open a window when I enter a closed room",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am susceptible to colds and fevers",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I tend to yawn in the afternoon",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get drowsy often",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My ankles tend to swell; can be worse at night",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get muscle cramps or charley horses; worse during exercise",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get shortness of breath with exertion",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get a dull pain in my chest or that radiates into my left arm; worse with exertion",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I bruise easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a tendency towards anemia",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get frequent nose-bleeds",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I hear noises in my head, am sensitive to frequencies or ringing in my ears",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get tension under my breastbone or a feeling of tightness; worse with exertion",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I experience dizziness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have dry skin",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I feel like I have burning feet",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have blurry vision",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have itching skin or my feet itch",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have excessive falling hair",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have frequent skin rashes",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get a bitter, metallic taste in my mouth in the morning",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My bowel movements are painful or difficult",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am a worrier, I feel insecure",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I feel queasy and get a headache over my eyes",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Greasy foods upset my digestion",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My stools are light-colored",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get pain between my shoulder blades",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I use laxatives",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My stools alternate from soft to watery",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a history of gallbladder attacks or gallstones",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get sneezing attacks",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I experience nightmares or bad dreams",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have bad breath (halitosis)",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Milk products cause digestive distress",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am sensitive to hot weather",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a burning or itching anal area",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I crave sweets",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have no taste for meat",
          title: "I have no taste for meat",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question2",
          title: "I have lower bowel gas several hours after eating",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get burning stomach sensations, which are relieved when I eat",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a coated tongue",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I pass large amounts of foul-smelling gas",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get indigestion 1/2 to 1 hour after eating; sometimes it can be as much as 3-4 hours",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: 'I have mucous colitis or "irritable bowel"',
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get gas shortly after eating",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get stomach bloating",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I experience insomnia",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get nervous",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I can't gain weight",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I cannot tolerate heat",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am highly emotional",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I flush easily",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get night sweats",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have thin, moist skin",
          title: "I have thin, moist skin",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I feel like I am inwardly trembling",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get heart palpitations",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have increased appetite without gaining weight",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My pulse is fast when I am at rest",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My eyelids and face twitch",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am irritable and restless",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I can't work under pressure",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have had a substantial increase in weight.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My appetite has noticeably decreased.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I fatigue easily.",
          title: "I fatigue easily. ",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have ringing in my ears.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am sleepy during the day.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am sensitive to cold.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have dry or scaly skin.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I suffer from constipation.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have mental sluggishness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My hair is coarse and falls out.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get headaches upon arising that wear off during the day.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a slow pulse, below 65.",
          title: "I have a slow pulse, below 65.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have frequent urination.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have impaired hearing.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have reduced initiative.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have memory issues.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have low blood pressure.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have increased sex drive.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get splitting headaches.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have decreased sugar tolerance.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have abnormal thirst.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get bloating of the abdomen.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question9",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have weight gain around my hips or waist.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have reduced or no sex drive.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a tendency to ulcers or colitis.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have an increased sugar tolerance or crave sugar.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have menstrual issues.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a lack of menstrual function.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Dizziness",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Headaches",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Hot flashes.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have increased blood pressure",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have hair growth on my face or body.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "There is sugar in my urine but I am not diabetic",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am female but I have masculine tendencies.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have weakness or dizziness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have chronic fatigue.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My nails are weak or ridged.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a tendency to hives.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have arthritic tendencies.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have an increase in perspiration.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have bowel disorders.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have poor circulation.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have swollen ankles.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I crave salt.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have brown spots or bronzing of the skin.",
          title: "I have brown spots or bronzing of the skin.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have allergies and I have a tendency to asthma.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I get weakness after colds or the flu.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My muscles and nervous system are exhausted.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have respiratory disorders.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have muscle weakness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Lack of stamina.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Drowsiness after eating.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have muscular soreness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have rapid heart beat.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am hyper-irritable.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have the feeling of a band around my head.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have melancholia or a feeling of sadness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have swelling of the ankles.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have diminished urination.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have a tendency to consume sweets or carbohydrates regularly.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have muscle spasms.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Blurred vision.",
          title: "Blurred vision.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Loss of muscular control.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Numbness",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Night sweats.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Rapid digestion.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Sensitivity to noise",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Redness of palms of hands and bottoms of feet.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Visible veins on chest and abdomen",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Hemorrhoids.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Apprehension, I have the feeling that something bad is going to happen.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have nervousness that causes me to lose my appetite.",
          title: "I have nervousness that causes me to lose my appetite.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have nervousness that gives me indigestion.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Gastritis.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "question23",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Forgetfulness.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have thinning hair.",
          title: "I have thinning hair.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am very easily fatigued.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have premenstrual tension.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have painful menstruation.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have depressed feelings before my menstruation.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have excessive and prolonged menstrual periods.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have painful breasts.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I menstruate too frequently.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have vaginal discharge.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have had a hysterectomy and/or ovaries removed",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I am menopausal and I have hot flashes.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "My menstruation is very little or absent.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have acne which is worse during menstruation.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "I have had long periods of depression.",
          choices: [
            { value: "never Occurs", text: "never Occurs" },
            {
              value: "occurs rarely and when they do they are mild",
              text: "occurs rarely and when they do they are mild",
            },
            {
              value: "occurs several times per month",
              text: "occurs several times per month",
            },
            {
              value: "occurs almost daily (constantly)",
              text: "occurs almost daily (constantly)",
            },
          ],
        },
        {
          type: "radiogroup",
          name: "Have you had Applied Kinesiology or another form of muscle testing done before?",
          choices: [
            { value: "Yes", text: "Yes" },
            { value: "No", text: "No" },
          ],
        },
      ],
    },
  ],
}; 

export default {
  name: "MyFirstSurvey",
  beforeMount(){
this.getLoginCookie();
},
  computed:{
    calcRotation(){
      const circleVal = ((this.progressPercentage/100)*180)
      return{
        transform:circleVal+'deg'
        }
    },
  },
  components: {
    Survey,
  },
  data() {
    const survey = new Model(surveyJson);
    survey.focusFirstQuestionAutomatic = false;
    survey.onComplete.add(this.alertResults);
    return {
      sd:false,
      rvu:1,
      progressPercentage:34,
      messageContents:"Contents",
      messageTitle:"Title",
      messageArray:[],
      newPass:"",
      logie:null,
      testDisplay:null,
      imageArray:null,
      formData:null,
      userImage:null,
      url:null,
      uploadedImage:null,
      loggedEmail:"brileeoaker@gmail.com",
      availableSurveys: [],
      responderSelected: "",
      num: 0,
      survey,
      dataForTable: [],
      firstname: "brinlee",
      lastname: "oaker",
      email: "brinleeoaker@gmail.com",
    };
  },
  methods: {
    getLoginCookie(){
        let CookieContent = document.cookie;
        let processedCookie = CookieContent.substring(3).replace("%40","@");
        this.loggedEmail = processedCookie;
        console.log(processedCookie);
      },
    async getMessages(){
      let apiOutput = await axios.post("https://webber.fenixvida.co.za/getMessages.php", {username:this.loggedEmail});
      this.messageArray = [];
      (await apiOutput).data.forEach((element) => {
        const newArray = element.split(",");
        if(newArray[3]==""){
          this.messageArray.push({messageID:newArray[0],title:newArray[1],contents:newArray[2],isViewed:"No"});
        }else{
          this.messageArray.push({messageID:newArray[0],title:newArray[1],contents:newArray[2],isViewed:"Yes"});
        }
      });
      console.log(this.messageArray);
    },
    readMessage(index){
      let i = index;
      //this.messageContents = this.messageArray.contents[i];
      this.messageContents = this.messageArray[i].contents;
      this.messageTitle = this.messageArray[i].title;
      console.log(this.messageContents);
    },
    setL(){
      console.log(document.cookie);
      //let formattedCookie = document.cookie.substring();
      //this.loggedEmail = formattedCookie;
    },
    async alertResults(sender) {
      const results = JSON.stringify(sender.data);
      const obj = JSON.parse(results);
      this.firstname = obj.FirstName;
      this.lastname = obj.LastName;
      this.email = obj.Email;
      let respose = await axios.post("https://webber.fenixvida.co.za/answersurvey.php", {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        answers: results,
      });
      console.log(respose);
    },
    async populateAvailableSurveys() {
      this.availableSurveys = [];
      let result = axios.post("https://webber.fenixvida.co.za/getSurveys.php");
      console.log((await result).data);
      (await result).data.forEach((element) => {
        const Earray = element.split(",");
        //let id equal everything after ","
        //let eFname = Earray[1];
        //let Adress equal everything before ","
        let eSname = Earray[0];
        const FullName = eSname;
        this.availableSurveys.push({ survey: FullName });
      });
    },
    lcl(){
      let x = document.cookie;
      console.log(x);
      //alert(localStorage.getItem("Type"));
    },
    surveySelect(event) {
      this.responderSelected = event.target.value;
    },
    async changeDefaultPass(ppp){
        this.newPass = ppp;
        let username = this.loggedEmail;
        let password = this.newPass;
        let apiOutput = await axios.post("https://webber.fenixvida.co.za/changeDefaultPass.php", {password:password,username:username});
        alert(apiOutput.data);
    },
    changePass(){
        //send mail to user with reset link
        //link directs to password reset
        //get old pass
        //get new pass
        //confirm new pass
        //check same and correct 
        //update DB
    },
    uploadImage(event){
      this.formData = new FormData();
      const file = event.target.files[0];
      let ImageName = file.name;
      let userEmail = this.loggedEmail;
      const reader = new FileReader();
      //let rawImg;
      reader.onloadend = () => {
            //rawImg = reader.result;
            this.url = reader.result;
            //console.log('raw',rawImg);
           // this.url = rawImg;
      }
      reader.readAsDataURL(file);
      //formData.append("image", this.url);
      this.formData.append("email", userEmail);
      this.formData.append("ImageName", ImageName);
      //let apiOutput = await axios.post("https://webber.fenixvida.co.za/uploadImage.php", formData);
      //console.log(apiOutput);
    },
    async resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
      return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      let ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL())
    }
  })
},
    async submitForm(){
        //change image size
        console.log('origsize:',((this.url.length)*2)/1000);
        this.url = await this.resizeImage(this.url,300,300);
        console.log('newsize:',((this.url.length)*2)/1000);
        this.formData.append("image", this.url);
        let apiOutput = await axios.post("https://webber.fenixvida.co.za/uploadImage.php", this.formData);
        console.log(apiOutput);
    },
    async aioImageUpload(event){
      this.formData = new FormData();
      const file = event.target.files[0];
      let ImageName = file.name;
      let userEmail = this.loggedEmail;
      const reader = new FileReader();
      //let rawImg;
      reader.onloadend = () => {
            //rawImg = reader.result;
            this.url = reader.result;
            //console.log('raw',rawImg);
           // this.url = rawImg;
      }
      reader.readAsDataURL(file);
      //formData.append("image", this.url);
      this.formData.append("email", userEmail);
      this.formData.append("ImageName", ImageName);
      //let apiOutput = await axios.post("https://webber.fenixvida.co.za/uploadImage.php", formData);
      //console.log(apiOutput);
        console.log('origsize:',((this.url.length)*2)/1000);
        this.url = await this.resizeImage(this.url,300,300);
        console.log('newsize:',((this.url.length)*2)/1000);
        this.formData.append("image", this.url);
        let apiOutput = await axios.post("https://webber.fenixvida.co.za/uploadImage.php", this.formData);
        console.log(apiOutput);
    },
    async getImages(){
      let apiOut = await axios.post("https://webber.fenixvida.co.za/showImage.php",{email:this.loggedEmail});
      console.log(apiOut);
      this.imageArray = apiOut.data;
      console.log("imgarray contents",this.imageArray);
    },
    async RemoveUserImage(){
      let result = await axios.post("https://webber.fenixvida.co.za/removeImage.php",{email:this.loggedEmail});
     
      //populate display box
      console.log(JSON.stringify(result.data));
      this.userImage = URL.createObjectURL(JSON.stringify(result.data));
      
    },
    async printSurvey() {
      //Full name to Fname and Sname
      const NameArray = this.responderSelected.split(" ");
      let firstname = NameArray[0];
      let lastname = NameArray[1];
      let response = await axios.post("https://webber.fenixvida.co.za/getAnswers.php", {
        firstname: firstname,
        surname: lastname,
      });
      const answerString = JSON.stringify(response.data);
      const JV = JSON.parse(answerString);
      const vOne = JSON.stringify(JV);
      const vTwo = vOne.substring(vOne.indexOf("Answers") + 10);
      const vThree = vTwo.substring(0, vTwo.length - 2);
      const vFour = vThree.replace(/\\/g, "");
      const Fna = JSON.parse(vFour);
      this.dataForTable = Fna;
    },
  },
};
</script>
<style>
#base{
  /* background-image: url("https://fenixvida.co.za/wp-content/uploads/2021/11/logo.svg");*/
}
#SurveyDiv {
  height: 100%; /* 100% Full-height */
  width: 50%; /* 0 width - change this with JavaScript */
  margin-left: 25%;
  background-color: #111; /* Black*/
}
#leftNav {
  height: 100%; /* 100% Full-height */
  width: 25%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.nb {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
#changePassword {
  transition: margin-left 0.5s;
  padding: 20px;
}
#changePassword label {
  flex-direction: column;
  display: flex;
}
table {
  font-family: "Open Sans", sans-serif;
  width: 750px;
  border-collapse: collapse;
  border: 3px solid #44475c;
  margin-left: 35%;
}

table th {
  text-transform: uppercase;
  text-align: left;
  background: #44475c;
  color: #fff;
  padding: 8px;
  min-width: 30px;
}

table td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7d82a8;
}
table td:last-child {
  border-right: none;
}
table tbody tr:nth-child(2n) td {
  background: #d4d8f9;
}
@media (min-width: 600px) {
  #squaregrid { grid-template-columns: repeat(2, 1fr); }
}
@media (min-width: 900px) {
  #squaregrid { grid-template-columns: repeat(3, 1fr); }
}
#squaregrid{
  margin-top: 20px !important;
  padding: 2%;
  margin-left: 60px;
  max-width: 90%;
  min-width: 26%;
  max-height:600px;
  min-height:200px;
  display: grid;
  grid-gap: 1rem;
  overflow-y: scroll;
}
.item{
  display: inline-block; 
  justify-content: center;
  max-height: 250px;
  color: blue;
  padding:5px;
}
.userImage{
  display: block;
  max-height: 240px;
}
#inboxMessages{
    display: block;
}

#message{
    height:40px;
}
#messageButton{
  display: inline-block; 
  justify-content: center;
 width:100%;
 height:100%;
    max-height: 120px;
    background-color:white;
    padding-left:10%;
    text-align: left;
    font-weight: 700;
    transition-duration: 0.4s;
    border-top:none;
}
#messageButton:hover {
  background-color: black; /* Green */
  color: white;
}
#inboxCtl{
  width:50%;
  height:100%;
  background-color:rgb(184, 184, 184);
}
#inboxCtl:focus{
  background-color:rgb(255, 255, 255);
  font-size: 30px;
  border-bottom: none;
  
}
#inboxCtl:visited{
  background-color:rgb(255, 255, 255);
  font-size: 30px;
  border-bottom: none;
}
#inboxHeader{
  height:40px;
}
.md-badge.md-dense {
    color: #f9f9f9;
    font-weight: 600;
    font-size: 9px;
    background-color: red;
}
.circle-wrap {
  margin: 5px auto;
  width: 150px;
  height: 150px;
  background: #fefcff;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background: #d2eaf1;
  line-height: 120px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  color: black;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
}
#DateWord{
  text-align: center;
  margin-top: 20px;
  margin-left: -5px;
  color: black;
  position: relative;
  display: flex;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  flex-wrap: wrap;
  align-content: center;
}
/* color animation */

/* 3rd progress bar */


.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #5C5C5C;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
}
.md-list {
    width: 320px;
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(#000, .12);
}
.md-radio {
    display: flex;
}
.md-divider.md-inset {
    margin-left: 10px;
}
.full-control > .md-list {
    width: 320px;
    max-width: 100%;
    height: 400px;
    display: inline-block;
    overflow: auto;
    border: 1px solid rgba(#000, .12);
    vertical-align: top;
  }
#inbox{
  display:flex;
  width:90%;
  margin-left:40px;
  margin-right:20px;
  margin-top:20px;
}
#mDisplay{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    width: 85%;
    margin-left: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top:20px;
    height:600px;
}
</style>
